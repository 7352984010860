import { Tag } from 'antd'
import { GetOrderItemTimelineResponse, GetOrderResponse, OrderItemResponse, OrderItemTimelineStatusType, OrderResponse } from '../services/OrderService'
import IntlMessage from '../components/util-components/IntlMessage'
import moment from 'moment'
import { Link, useLocation } from 'react-router-dom'
import { useCallback, useMemo } from 'react'
import { Theme as ReactTableLibraryTheme } from '@table-library/react-table-library/types/theme'
import { PresetColorType, PresetStatusColorType } from 'antd/lib/_util/colors'
import { useDispatch } from 'react-redux'
import { TableOrderItem } from '../views/app-views/OrderView/components/OrderErpTable/OrderErpTable.type'
import { setOrderItemTimelineModalState } from '../store/order-items-table/reducer'

export const enum OrderStatusEnum {
  Proposal = 'proposal',
  Agreement = 'agreement',
  InProgress = 'in_progress',
  Received = 'received',
  Delivered = 'delivered',
  Success = 'success',
  Cancel = 'cancel',
}

export const ORDER_STATUSES: Array<OrderStatusEnum> = [
  OrderStatusEnum.Proposal,
  OrderStatusEnum.Agreement,
  OrderStatusEnum.InProgress,
  OrderStatusEnum.Received,
  OrderStatusEnum.Delivered,
  OrderStatusEnum.Success,
  OrderStatusEnum.Cancel,
]

export const enum OrderStatusGroupEnum {
  Proposals = 'proposals',
  Active = 'active',
  Archive = 'archive',
}

export const enum PaymentConditionCodeEnum {
  PartPrepaid = 'part_prepaid',
}

export interface OrderResponsiblePerson {
  id: number;
  name: string;
  email: string;
}

export interface IntegratedDeliveryNote {
  idnId: string;
  date: number;
  positionsCount: number;
  sum: number;
  pdfUrl: string;
}

export interface Order {
  id: number;
  name: string;
  status: OrderStatusEnum;
  statusGroup: OrderStatusGroupEnum;
  dealBudget: number;
  paymentConditionCode: PaymentConditionCodeEnum;
  paymentConditionDescription: string;
  paymentConditionDescriptionEn: string;
  paymentValue: number;
  paymentDeadlineDate: number;
  billId: string;
  billPdfUrl: string;
  proposalId: string;
  proposalPdfUrl: string;
  approvalDate: number;
  supplyDeadlineDate: number;
  supplyDeadlineDate2: number;
  maxSupplyDeadlineDate: number;
  deliveryCity: string;
  items: Array<OrderItem>;
  supplyDate: number;
  responsiblePersonFromAppScience: OrderResponsiblePerson;
  responsiblePersons: OrderResponsiblePerson[];
  integratedDeliveryNotes: Array<IntegratedDeliveryNote>;
}

export const enum OrderItemStatusEnum {
  Cancelled = 'cancelled',
  SentToClient = 'sent_to_client',
  Obo = 'obo',
  CustomsCleared = 'customs_cleared',
  OnBorder = 'on_border',
  SentToRu = 'sent_to_ru',
  SentToTransit = 'sent_to_transit',
  ArrivedToHub = 'arrived_to_hub',
  SentToHub = 'sent_to_hub',
  OnTsw = 'on_tsw',
  StoreDelay = 'store_delay',
  Ordered = 'ordered',
  Procurement = 'procurement',
}

export const ORDER_ITEM_STATUSES: Array<OrderItemStatusEnum> = [
  OrderItemStatusEnum.Procurement,
  OrderItemStatusEnum.Ordered,
  OrderItemStatusEnum.StoreDelay,
  OrderItemStatusEnum.OnTsw,
  OrderItemStatusEnum.SentToHub,
  OrderItemStatusEnum.ArrivedToHub,
  OrderItemStatusEnum.SentToTransit,
  OrderItemStatusEnum.SentToRu,
  OrderItemStatusEnum.OnBorder,
  OrderItemStatusEnum.CustomsCleared,
  OrderItemStatusEnum.Obo,
  OrderItemStatusEnum.SentToClient,
  OrderItemStatusEnum.Cancelled,
]

export interface OrderItem {
  id: number;
  name: string;
  quantity: number;
  totalPrice: number;
  status: OrderItemStatusEnum;
  statusDescription: string;
  statusDescriptionEn: string;
  expectedDeliveryDate: number;
  deliveryCondition: string;
  lastStatusDate: number;
  salesComments: string;
  billPosition: number;
}

export function getWaitingForPaymentDays(supplyUnixDate: number): number {
  if (!supplyUnixDate) {
    return 0
  }

  return moment()
    .diff(
      moment.unix(supplyUnixDate),
      'days',
    )
}


export function stringDateToTimestamp(stringDate: string): number {
  if (!stringDate?.length) {
    return 0
  }

  return new Date(stringDate).getTime()
}

// 2021-08-30 => 1630281600
export function stringDateToUnixTimestamp(stringDate: string): number {
  return stringDateToTimestamp(stringDate) / 1000
}

export function unixTimestampToFormattedDatetime(unixTimestamp: number): string {
  if (!unixTimestamp) {
    return ''
  }

  return moment.unix(unixTimestamp).format('HH:mm DD.MM.YYYY')
}

export function unixTimestampToFormattedDate(unixTimestamp: number): string {
  if (!unixTimestamp) {
    return ''
  }

  return moment.unix(unixTimestamp).format('DD.MM.YYYY')
}

export function unixTimestampToTimestamp(unixTimestamp: number): number {
  return unixTimestamp * 1000
}

function mapOrderItemResponseToOrderItem(response: OrderItemResponse): OrderItem {
  return {
    deliveryCondition: '', // TODO
    expectedDeliveryDate: stringDateToUnixTimestamp(response?.expected_delivery_date),
    id: response?.id,
    name: response?.name,
    quantity: response?.count,
    status: response?.status as OrderItemStatusEnum,
    statusDescription: response?.status_desc,
    statusDescriptionEn: response?.status_desc_en,
    totalPrice: response?.price,
    lastStatusDate: stringDateToUnixTimestamp(response?.last_status_date),
    salesComments: response?.sales_comments,
    billPosition: response?.bill_position || 0,
  }
}

export function mapGetOrderResponseToOrder(response: GetOrderResponse): Order | null {
  if (!response?.order) {
    return null
  }

  const price: number = parseInt(response?.order?.price, 10) || 0

  return {
    billId: response?.order?.bill_number,
    billPdfUrl: response?.bill?.pdf_url,
    approvalDate: response?.order?.approved_at,
    dealBudget: price,
    deliveryCity: response?.order?.delivery_city,
    id: response?.order?.id,
    items: response?.items?.map(mapOrderItemResponseToOrderItem),
    name: response?.order?.name,
    paymentConditionCode: response?.order?.pay_condition_code as PaymentConditionCodeEnum,
    paymentConditionDescription: response?.order?.pay_condition_desc,
    paymentConditionDescriptionEn: response?.order?.pay_condition_desc_en,
    paymentDeadlineDate: response?.order?.payment_deadline,
    paymentValue: price - (response?.order?.money_received || 0),
    proposalId: `${response?.order?.id}`,
    proposalPdfUrl: response?.proposal_pdf_url,
    status: response?.order?.status as OrderStatusEnum,
    statusGroup: response?.order?.status_group as OrderStatusGroupEnum,
    supplyDeadlineDate: response?.order?.supply_deadline,
    supplyDeadlineDate2: response?.order?.supply_deadline2,
    maxSupplyDeadlineDate: Math.max(
      response?.order?.supply_deadline || 0,
      response?.order?.supply_deadline2 || 0,
    ),
    supplyDate: stringDateToUnixTimestamp(response?.order?.supply_date),
    responsiblePersonFromAppScience: {
      id: 0,
      name: response?.order?.responsible_user_name ?? '',
      email: response?.order?.responsible_user_email ?? '',
    },
    responsiblePersons: (response?.order?.contacts ?? []).map(contact => ({
      id: contact?.id ?? null,
      name: contact?.name ?? '',
      email: contact?.email ?? '',
    })),
    integratedDeliveryNotes: (response?.upd ?? []).map(idn => ({
      idnId: idn.document_number ?? null,
      date: stringDateToUnixTimestamp(idn?.document_date),
      positionsCount: idn?.items?.length ?? 0,
      sum: idn?.sum ?? 0,
      pdfUrl: idn?.pdf_url ?? '',
    })),
  }
}

export function mapOrderResponseToOrder(response: OrderResponse): Order {
  const price: number = parseInt(response?.price, 10) || 0

  return {
    billId: response?.bill_number,
    billPdfUrl: '',
    approvalDate: response?.approved_at,
    dealBudget: price,
    deliveryCity: response?.delivery_city,
    id: response?.id,
    items: [],
    name: response?.name,
    paymentConditionCode: response?.pay_condition_code as PaymentConditionCodeEnum,
    paymentConditionDescription: response?.pay_condition_desc,
    paymentConditionDescriptionEn: response?.pay_condition_desc_en,
    paymentDeadlineDate: response?.payment_deadline,
    paymentValue: price,
    proposalId: `${response?.id}`,
    proposalPdfUrl: '',
    status: response?.status as OrderStatusEnum,
    statusGroup: response?.status_group as OrderStatusGroupEnum,
    supplyDeadlineDate: response?.supply_deadline,
    supplyDeadlineDate2: response?.supply_deadline2,
    maxSupplyDeadlineDate: Math.max(
      response?.supply_deadline || 0,
      response?.supply_deadline2 || 0,
    ),
    supplyDate: stringDateToUnixTimestamp(response?.supply_date),
    responsiblePersonFromAppScience: {
      id: 0,
      name: response?.responsible_user_name ?? '',
      email: response?.responsible_user_email ?? '',
    },
    responsiblePersons: (response?.contacts ?? []).map(contact => ({
      id: contact?.id ?? null,
      name: contact?.name ?? '',
      email: contact?.email ?? '',
    })),
    integratedDeliveryNotes: [],
  }
}

export const ORDER_ITEM_STATUS_TO_PRIORITY: Record<OrderItemStatusEnum, number> = {
  [OrderItemStatusEnum.Cancelled]: 12,
  [OrderItemStatusEnum.SentToClient]: 11,
  [OrderItemStatusEnum.Obo]: 10,
  [OrderItemStatusEnum.CustomsCleared]: 9,
  [OrderItemStatusEnum.OnBorder]: 8,
  [OrderItemStatusEnum.SentToRu]: 7,
  [OrderItemStatusEnum.SentToTransit]: 6,
  [OrderItemStatusEnum.ArrivedToHub]: 5,
  [OrderItemStatusEnum.SentToHub]: 4,
  [OrderItemStatusEnum.OnTsw]: 3,
  [OrderItemStatusEnum.StoreDelay]: 2,
  [OrderItemStatusEnum.Ordered]: 1,
  [OrderItemStatusEnum.Procurement]: 0,
}

export const ORDER_STATUS_TO_PRIORITY: Record<OrderStatusEnum, number> = {
  [OrderStatusEnum.Proposal]: 0,
  [OrderStatusEnum.Agreement]: 1,
  [OrderStatusEnum.InProgress]: 2,
  [OrderStatusEnum.Delivered]: 3,
  [OrderStatusEnum.Received]: 4,
  [OrderStatusEnum.Success]: 5,
  [OrderStatusEnum.Cancel]: 6,
}

type BadgeConfig = {
  messageRu: string;
  messageEn: string;
  color: PresetColorType | PresetStatusColorType | string;
}

const DEFAULT_BADGE_CONFIG: BadgeConfig = {
  messageRu: '',
  messageEn: '',
  color: 'default',
}

export const ORDER_STATUS_TO_BADGE_CONFIG: Record<OrderStatusEnum, BadgeConfig> = {
  [OrderStatusEnum.Proposal]: {
    messageRu: 'Proposal formation',
    messageEn: 'Proposal formation',
    color: 'geekblue',
  },
  [OrderStatusEnum.Agreement]: {
    messageRu: 'Proposal in agreement',
    messageEn: 'Proposal in agreement',
    color: 'gold',
  },
  [OrderStatusEnum.InProgress]: {
    messageRu: 'In progress',
    messageEn: 'In progress',
    color: 'geekblue',
  },
  [OrderStatusEnum.Received]: {
    messageRu: 'Completely in stock',
    messageEn: 'Completely in stock',
    color: 'orange',
  },
  [OrderStatusEnum.Delivered]: {
    messageRu: 'Completely delivered',
    messageEn: 'Completely delivered',
    color: 'cyan',
  },
  [OrderStatusEnum.Success]: {
    messageRu: 'Successfully finished',
    messageEn: 'Successfully finished',
    color: 'rgba(33,181,115,.6)',
  },
  [OrderStatusEnum.Cancel]: {
    messageRu: 'Cancelled',
    messageEn: 'Cancelled',
    color: 'red',
  },
}

export const ORDER_ITEM_STATUS_TO_BADGE_CONFIG: Record<OrderItemStatusEnum, BadgeConfig> = {
  [OrderItemStatusEnum.Cancelled]: {
    messageRu: 'D0 Отменено',
    messageEn: 'D0 Cancelled',
    color: 'gray',
  },
  [OrderItemStatusEnum.SentToClient]: {
    messageRu: 'C4 Направлено клиенту',
    messageEn: 'C4 Sent to client',
    color: 'success',
  },
  [OrderItemStatusEnum.Obo]: {
    messageRu: 'C3 Прибыло на склад в Москве',
    messageEn: 'C3 Arrived to local warehouse',
    color: 'green',
  },
  [OrderItemStatusEnum.CustomsCleared]: {
    messageRu: 'C2 Таможня пройдена',
    messageEn: 'C2 Customs passed',
    color: 'lime',
  },
  [OrderItemStatusEnum.OnBorder]: {
    messageRu: 'C1 Прибыло на таможню',
    messageEn: 'C1 Customs',
    color: 'gold',
  },
  [OrderItemStatusEnum.SentToRu]: {
    messageRu: 'B4 Отправлено в РФ',
    messageEn: 'B4 Sent to final country',
    color: 'orange',
  },
  [OrderItemStatusEnum.SentToTransit]: {
    messageRu: 'B3 Отправлено на транзитный узел',
    messageEn: 'B3 Sent to hub II',
    color: 'volcano',
  },
  [OrderItemStatusEnum.ArrivedToHub]: {
    messageRu: 'B2 Пришло на хаб',
    messageEn: 'B2 Arrived to hub',
    color: 'red',
  },
  [OrderItemStatusEnum.SentToHub]: {
    messageRu: 'B1 Отправлено на хаб',
    messageEn: 'B1 Sent to hub',
    color: 'magenta',
  },
  [OrderItemStatusEnum.OnTsw]: {
    messageRu: 'A4 Получили о поставщика',
    messageEn: 'A4 Received from supplier',
    color: 'purple',
  },
  [OrderItemStatusEnum.StoreDelay]: {
    messageRu: 'A3 Задержка от поставщика',
    messageEn: 'A3 Supplier delay',
    color: 'geekblue',
  },
  [OrderItemStatusEnum.Ordered]: {
    messageRu: 'A2 Заказано у поставщика',
    messageEn: 'A2 Ordered',
    color: 'blue',
  },
  [OrderItemStatusEnum.Procurement]: {
    messageRu: 'A1 Передано в закупку',
    messageEn: 'A1 Processed to procurement team',
    color: 'blue',
  },
}

export function mapOrderStatusToMessage(
  status: OrderStatusEnum,
  messages: Record<string, string>,
): string {
  const { color } = ORDER_STATUS_TO_BADGE_CONFIG[status] ?? DEFAULT_BADGE_CONFIG
  if (!color || !status) {
    return ''
  }

  return messages['orders.status.' + status]
}

export function mapOrderStatusToBadge(status: OrderStatusEnum): React.ReactNode {
  const { color } = ORDER_STATUS_TO_BADGE_CONFIG[status] ?? DEFAULT_BADGE_CONFIG
  if (!color || !status) {
    return null
  }

  return (
    <Tag
      color={color}
      style={{ lineHeight: '20px' }}
    >
      <span className='font-weight-semibold'>
        <IntlMessage id={'orders.status.' + status} />
      </span>
    </Tag>
  )
}

export function mapOrderItemStatusToMessage(
  status: OrderItemStatusEnum,
  statusDescription: string,
  lastStatusDate: number,
  locale: LocaleEnum,
): string {
  const { messageRu, messageEn, color } = ORDER_ITEM_STATUS_TO_BADGE_CONFIG[status] ?? DEFAULT_BADGE_CONFIG
  if (!color) {
    return ''
  }

  const message: string =
    locale === LocaleEnum.Ru
      ? messageRu
      : messageEn

  const statusMessage: string = statusDescription || message
  if (lastStatusDate === 0) {
    return statusMessage
  }

  return `${unixTimestampToFormattedDate(lastStatusDate)} ${statusMessage}`
}

type OrderItemStatusBadgeProps = {
  color: PresetColorType | PresetStatusColorType | string;
  lastStatusDate: number;
  statusDescription: string;
  itemId: string;
}

export function OrderItemStatusBadge({
  color,
  lastStatusDate,
  statusDescription,
  itemId,
}: OrderItemStatusBadgeProps) {
  const dispatch = useDispatch()
  const openOrderItemTimelineModal = useCallback(() => {
    dispatch(setOrderItemTimelineModalState({
      isOpen: true,
      itemId,
    }))
  }, [dispatch, itemId])

  return (
    <Tag
      color={color}
      onClick={openOrderItemTimelineModal}
    >
      <span className='font-weight-semibold'>
        {lastStatusDate !== 0 && (
          <span>{unixTimestampToFormattedDate(lastStatusDate)}{' '}</span>
        )}
        {statusDescription}
      </span>
    </Tag>
  )
}

export function mapOrderItemStatusToBadge(
  orderItem: TableOrderItem,
  locale: LocaleEnum,
): React.ReactNode {
  const { status, statusDescription, statusDescriptionEn, lastStatusDate, id } = orderItem
  const { messageRu, messageEn, color } = ORDER_ITEM_STATUS_TO_BADGE_CONFIG[status] ?? DEFAULT_BADGE_CONFIG
  if (!color) {
    return null
  }

  const statusDescriptionText: string =
    locale === LocaleEnum.Ru
      ? statusDescription
      : statusDescriptionEn
  const defaultStatusDescription: string =
    locale === LocaleEnum.Ru
      ? messageRu
      : messageEn

  return (
    <OrderItemStatusBadge
      color={color}
      lastStatusDate={lastStatusDate}
      statusDescription={statusDescriptionText || defaultStatusDescription}
      itemId={id}
    />
  )
}

export type OrderItemTimelineStatus = {
  timestamp: number;
  statusRu: string;
  statusEn: string;
  statusType: OrderItemTimelineStatusType;
}
export type OrderItemTimeline = {
  statuses: Array<OrderItemTimelineStatus>;
}

export function mapOrderItemTimelineResponseToOrderItemTimeline(
  response: GetOrderItemTimelineResponse,
): OrderItemTimeline {
  return {
    statuses: (response?.status_list ?? [])
      .map(statusResponse => ({
        timestamp: stringDateToUnixTimestamp(statusResponse?.time),
        statusRu: statusResponse?.status_desc_ru,
        statusEn: statusResponse?.status_desc_en,
        statusType: statusResponse?.status_type,
      })),
  }
}

export function getOrderItemStatusTitle(
  status: OrderItemStatusEnum,
  locale: LocaleEnum,
): string {
  const { messageRu = '', messageEn = '' } = ORDER_ITEM_STATUS_TO_BADGE_CONFIG[status] ?? {}
  const message =
    locale === LocaleEnum.Ru
      ? messageRu
      : messageEn
  return message || status
}

export const enum ThemeColorEnum {
  Light = 'light',
  Dark = 'dark',
}

export function getCustomTableTheme(theme: ThemeColorEnum): ReactTableLibraryTheme {
  return {
    Row: `
      &:hover {
        background-color: ${theme === ThemeColorEnum.Light ? '#e7f5ff' : '#444'};
      }
    `,
  }
}

export const enum LocaleEnum {
  Ru = 'ru',
  En = 'en',
}

export const enum ViewTypeEnum {
  Grid = 'grid',
  AntDesignTable = 'ant',
  ErpTable = 'erp',
}

export function useViewType(defaultViewType: ViewTypeEnum): ViewTypeEnum {
  const { search } = useLocation()
  const query = useMemo(() => new URLSearchParams(search), [search])

  return query.get('viewType') as ViewTypeEnum ?? defaultViewType
}

function getOrderUrl(orderId: number): string {
  return `/order/${orderId}`
}

type LinkToOrderProps = {
  orderId: number;
}

export const LinkToOrder = (props: LinkToOrderProps) => {
  const { orderId } = props
  if (!orderId) {
    return null
  }

  return (
    <Link to={getOrderUrl(orderId)}>
      {orderId}
    </Link>
  )
}

// 12345678 => '12 345 678'
export function formatPrice(price: number): string {
  return `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export const ORDER_STATUS_GROUP_TO_ORDER_STATUSES: Record<OrderStatusGroupEnum, Array<OrderStatusEnum>> = {
  [OrderStatusGroupEnum.Proposals]: [
    OrderStatusEnum.Proposal,
    OrderStatusEnum.Agreement,
  ],
  [OrderStatusGroupEnum.Active]: [
    OrderStatusEnum.InProgress,
    OrderStatusEnum.Received,
    OrderStatusEnum.Delivered,
  ],
  [OrderStatusGroupEnum.Archive]: [
    OrderStatusEnum.Success,
    OrderStatusEnum.Cancel,
  ],
}

export function getSetsIntersection<T>(sets: Array<Set<T>>): Set<T> {
  if (!sets.length) {
    return new Set<T>()
  }

  const firstSet = sets[0]
  if (sets.length === 1) {
    return firstSet
  }

  const restSets: Array<Set<T>> = sets.slice(1)

  return [...firstSet.values()].reduce((intersection, value) => {
    const isInAllRestSets: boolean = restSets.every(set => set.has(value))
    if (!isInAllRestSets) {
      return intersection
    }

    return intersection.add(value)
  }, new Set<T>())
}

export const EMPTY_VALUE_ID = 'EMPTY_VALUE_ID'
