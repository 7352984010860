import { DataTableColumnInfo } from '@appscience/data-table'
import { caseInsensitiveCompare, defaultCompare } from '@appscience/utils'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { selectLocale } from '../../../../../../store/theme/selectors'
import { LocaleEnum, formatPrice, mapOrderItemStatusToBadge, unixTimestampToFormattedDate } from '../../../../../../utils/orders.util'
import { TableOrderItem, TableOrderItemColumnId } from '../OrderErpTable.type'
import { compareOrderItemStatuses } from '../OrderErpTable.utils'

export function useOrderErpTableColumns(): Array<DataTableColumnInfo<TableOrderItemColumnId, TableOrderItem>> {
  const intl = useIntl()
  const locale: LocaleEnum = useSelector(selectLocale)

  return [{
    id: 'billPosition',
    content: (orderItem: TableOrderItem) => orderItem.billPosition || '-',
    fr: 3,
    minWidth: '60px',
    title: intl.messages['orderTable.columns.billPosition'],
    sort: (a: TableOrderItem, b: TableOrderItem) => defaultCompare(a.billPosition, b.billPosition),
  }, {
    id: 'name',
    content: (orderItem: TableOrderItem) => orderItem.name || '-',
    fr: 15,
    minWidth: '300px',
    title: intl.messages['orderTable.columns.name'],
    sort: (a: TableOrderItem, b: TableOrderItem) => caseInsensitiveCompare(a.name, b.name),
  }, {
    id: 'quantity',
    content: (orderItem: TableOrderItem) => orderItem.quantity || '-',
    fr: 5,
    minWidth: '100px',
    title: intl.messages['orderTable.columns.quantity'],
    sort: (a: TableOrderItem, b: TableOrderItem) => (a.quantity || 0) - (b.quantity || 0),
  }, {
    id: 'totalPrice',
    content: (orderItem: TableOrderItem) => formatPrice(orderItem.totalPrice || 0) || '-',
    fr: 5,
    minWidth: '100px',
    title: intl.messages['orderTable.columns.totalPrice'],
    sort: (a: TableOrderItem, b: TableOrderItem) => (a.totalPrice || 0) - (b.totalPrice || 0),
  }, {
    id: 'status',
    content: (orderItem: TableOrderItem) => mapOrderItemStatusToBadge(
      orderItem,
      locale,
    ) || '-',
    fr: 10,
    minWidth: '300px',
    title: intl.messages['orderTable.columns.status'],
    sort: (a: TableOrderItem, b: TableOrderItem) => compareOrderItemStatuses(a, b),
  }, {
    id: 'expectedDeliveryDate',
    content: (orderItem: TableOrderItem) => unixTimestampToFormattedDate(orderItem?.expectedDeliveryDate) || '-',
    fr: 6,
    minWidth: '120px',
    title: intl.messages['orderTable.columns.expectedDeliveryDate'],
    sort: (a: TableOrderItem, b: TableOrderItem) => (a.expectedDeliveryDate || 0) - (b.expectedDeliveryDate || 0),
  }, {
    id: 'salesComments',
    content: (orderItem: TableOrderItem) => orderItem?.salesComments || '-',
    fr: 10,
    minWidth: '150px',
    title: intl.messages['orderTable.columns.salesComments'],
    sort: (a: TableOrderItem, b: TableOrderItem) => caseInsensitiveCompare(a.salesComments, b.salesComments),
  }]
}