export function getEnvironment(): string {
  switch (window.location.hostname) {
    case PROD_HOSTNAME:
      return ENV_PROD
    case STAGE_HOSTNAME:
      return ENV_STAGE
    case TEST_HOSTNAME:
      return ENV_TEST
  }
  return ENV_DEV
}

export const ENV_PROD = 'prod'
export const ENV_DEV = 'dev'
export const ENV_STAGE = 'stage'
export const ENV_TEST = 'test'

export const PROD_HOSTNAME = 'customers.appscience.pro'
export const STAGE_HOSTNAME = 'stage.customers.appscience.pro'
export const TEST_HOSTNAME = 'test.customers.appscience.pro'

export const PROD_API_URL = 'https://api.appscience.pro'
export const DEV_API_URL = 'https://common-dev.api.appscience.pro'
export const STAGE_API_URL = 'https://stage.api.appscience.pro'
export const TEST_API_URL = 'https://test.api.appscience.pro'
export const CURRENT_API_URL = (():string => {
  switch (getEnvironment()) {
    case ENV_PROD:
      return PROD_API_URL
    case ENV_STAGE:
      return STAGE_API_URL
    case ENV_TEST:
      return TEST_API_URL
  }
  return DEV_API_URL
})()

export const API_URL = {
  AVAILABILITY: process.env.REACT_APP_AVAILABILITY_URL || CURRENT_API_URL + '/availability',
  AUTH: process.env.REACT_APP_AUTH_URL || CURRENT_API_URL + '/auth',
  CURRENCIES: process.env.REACT_APP_CURRENCIES_URL || CURRENT_API_URL + '/currencies',
  CUSTOMER_AREA: process.env.REACT_APP_CUSTOMER_AREA_URL || CURRENT_API_URL + '/customer-area',
  EXCEL_CONVERTER: process.env.REACT_APP_EXCEL_CONVERTER_URL || CURRENT_API_URL + '/excel-converter',
  METABASE_EMBEDDING: process.env.REACT_APP_METABASE_EMBEDDING_URL || CURRENT_API_URL + '/metabase_embedding',
  PARTNER_PRODUCTS: process.env.REACT_APP_PARTNER_PRODUCTS_URL || CURRENT_API_URL + '/partner-products',
  PROPOSALS: process.env.REACT_APP_PROPOSALS_URL || CURRENT_API_URL + '/proposals',
  SUGGEST: process.env.REACT_APP_SUGGEST_URL || CURRENT_API_URL + '/suggest',
  TWIN: process.env.REACT_APP_TWIN_URL || CURRENT_API_URL + '/twin',
}
