import { Col, Row, Typography } from 'antd'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { LinkToDocument } from '../../../../../components/custom-components/LinkToDocument/LinkToDocument'
import { selectCurrentTheme, selectLocale } from '../../../../../store/theme/selectors'
import {
  LocaleEnum,
  Order,
  ThemeColorEnum,
  formatPrice,
  mapOrderStatusToBadge,
  unixTimestampToFormattedDate,
} from '../../../../../utils/orders.util'
import { ResponsiblePersons } from '../../../orders/components/OrdersErpTable/content/ResponsiblePersons/ResponsiblePersons'
import styles from './OrderTableHeader.module.css'

const { Text } = Typography

type OrderTableHeaderProps = {
  order: Order | null;
}

export const OrderTableHeader = (props: OrderTableHeaderProps) => {
  const intl = useIntl()
  const locale: LocaleEnum = useSelector(selectLocale)
  const messages = intl.messages as Record<string, string>
  const {order} = props
  const theme: ThemeColorEnum = useSelector(selectCurrentTheme) as ThemeColorEnum
  const emailSubject = `№ ${order?.id}: ${order?.name}`
  const paymentConditionDescription: string | undefined =
    locale === LocaleEnum.Ru
      ? order?.paymentConditionDescription
      : order?.paymentConditionDescriptionEn
  const isRuLocale: boolean = locale === LocaleEnum.Ru

  if (!order) {
    return null
  }

  return (
    <div className={styles['order-header']}>
      <h2 className={styles['order-header__title']}>
        {messages['orderHeader.orderId']}{order?.id}:
        {' '}
        <span className={styles['order-header__name']}>
          {order?.name}
        </span>
      </h2>
      <div
        className={`
          ${styles['order-header__properties']}
          ${theme === ThemeColorEnum.Light ? styles['order-header__properties--light-theme'] : ''}
        `}
      >
        <Row gutter={[8, 8]}>
          <Col
            span={5}
            xs={6}
            xl={5}
            className='d-flex justify-content-end'
          >
            <Text strong>
              {messages['orderHeader.status']}:
            </Text>
          </Col>
          <Col
            span={4}
            xs={6}
            xl={4}
          >
            {mapOrderStatusToBadge(order?.status)}
          </Col>
          <Col
            span={3}
            xs={6}
            xl={3}
            className='d-flex justify-content-end'
          >
            <Text strong>
              {messages['orderHeader.dealBudget']}:
            </Text>
          </Col>
          <Col
            span={6}
            xs={6}
            xl={6}
          >
            {formatPrice(order?.dealBudget)}
            {isRuLocale && (
              ' ₽ '
            )}
            {!isRuLocale && (
              ' '
            )}
            {paymentConditionDescription && `(${paymentConditionDescription})`}
          </Col>
          <Col
            span={3}
            xs={6}
            xl={3}
            className='d-flex justify-content-end'
          >
            <Text strong>
              {messages['orderHeader.approvalDate']}:
            </Text>
          </Col>
          <Col
            span={2}
            xs={6}
            xl={2}
          >
            {unixTimestampToFormattedDate(order?.approvalDate) || '-'}
          </Col>
          <Col
            span={5}
            xs={6}
            xl={5}
            className='d-flex justify-content-end'
          >
            <Text strong>
              {messages['orderHeader.responsiblePerson']}:
            </Text>
          </Col>
          <Col
            span={4}
            xs={6}
            xl={4}
          >
            {order?.responsiblePersons.length === 0 && '-'}
            {order?.responsiblePersons.length !== 0 && (
              <ResponsiblePersons
                persons={order?.responsiblePersons}
                emailSubject={emailSubject}
                shouldShowEmail
              />
            )}
          </Col>
          <Col
            span={3}
            xs={6}
            xl={3}
            className='d-flex justify-content-end'
          >
            <Text strong>
              {messages['orderHeader.paymentValue']}:
            </Text>
          </Col>
          <Col
            span={6}
            xs={6}
            xl={6}
          >
            {formatPrice(order?.paymentValue)}
            {isRuLocale && (
              ' ₽ '
            )}
            {!isRuLocale && (
              ' '
            )}
            {order?.paymentDeadlineDate > 0 && (
              `(${messages['orderHeader.payBy']} ${unixTimestampToFormattedDate(order?.paymentDeadlineDate)})`
            )}
          </Col>
          <Col
            span={3}
            xs={6}
            xl={3}
            className='d-flex justify-content-end'
          >
            <Text strong>
              {messages['orderHeader.maxSupplyDeadlineDate']}:
            </Text>
          </Col>
          <Col
            span={2}
            xs={6}
            xl={2}
          >
            {unixTimestampToFormattedDate(order?.maxSupplyDeadlineDate) || '-'}
          </Col>
          <Col
            span={5}
            xs={6}
            xl={5}
            className='d-flex justify-content-end'
          >
            <Text strong>
              {messages['orderHeader.responsiblePersonFromAppScience']}:
            </Text>
          </Col>
          <Col
            span={4}
            xs={6}
            xl={4}
          >
            {!order?.responsiblePersonFromAppScience?.name && '-'}
            {order?.responsiblePersonFromAppScience?.name && (
              <ResponsiblePersons
                persons={[order?.responsiblePersonFromAppScience]}
                emailSubject={emailSubject}
                shouldShowEmail
              />
            )}
          </Col>
          <Col
            span={3}
            xs={6}
            xl={3}
            className='d-flex justify-content-end'
          >
            <Text strong>
              {messages['orderHeader.billId']}:
            </Text>
          </Col>
          <Col
            span={6}
            xs={6}
            xl={6}
          >
            <LinkToDocument
              text={order?.billId}
              url={order?.billPdfUrl}
            />
          </Col>
          <Col
            span={3}
            xs={6}
            xl={3}
            className='d-flex justify-content-end'
          >
            <Text strong>
              {messages['orderHeader.deliveryCity']}:
            </Text>
          </Col>
          <Col
            span={2}
            xs={6}
            xl={2}
          >
            {order?.deliveryCity || 'не указан'}
          </Col>
          <Col
            span={5}
            xs={6}
            xl={5}
          ></Col>
          <Col
            span={4}
            xs={6}
            xl={4}
          ></Col>
          <Col
            span={3}
            xs={6}
            xl={3}
            className='d-flex justify-content-end'
          >
            <Text strong>
              {messages['orderHeader.proposalId']}:
            </Text>
          </Col>
          <Col
            span={6}
            xs={6}
            xl={6}
          >
            <LinkToDocument
              text={order?.proposalId}
              url={order?.proposalPdfUrl}
            />
          </Col>
          <Col
            span={3}
            xs={6}
            xl={3}
          ></Col>
          <Col
            span={2}
            xs={6}
            xl={2}
          ></Col>
        </Row>
      </div>
    </div>
  )
}