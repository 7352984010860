import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

declare global {
  interface Window {
    ym: (metricId: number, eventName: string, url: string) => void
  }
}

const YANDEX_METRIC_ID = 89455156

export function usePageViews() {
  const location = useLocation()

  useEffect(() => {
    window.ym(
      YANDEX_METRIC_ID,
      'hit',
      window.location.href,
    )
  }, [location])
}
