import { GlobalMultiSelect } from '@appscience/data-table'
import { checkNever } from '@appscience/utils'
import {SelectItem} from '@mantine/core/lib/components/Select/types'
import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import IntlMessage from '../../../../../../components/util-components/IntlMessage'
import { setOrderItemsTableGlobalFilter } from '../../../../../../store/order-items-table/reducer'
import { selectOrderItemsTableGlobalFilter, selectTableOrderItems } from '../../../../../../store/order-items-table/selectors'
import { OrderItem } from '../../../../../../utils/orders.util'
import { mapToSelectItem } from '../../../../orders/components/OrdersErpTable/OrdersErpTable.utils'
import { TableOrderItem } from '../OrderErpTable.type'
import { mapOrderItemToTableOrderItem } from '../OrderErpTable.utils'

type NameData = TitleWithId<string> & {
  name: string;
}

type OrderItemsTableGlobalFiltersData = {
  nameIds: Array<NameData>;
}

type FilterType = keyof OrderItemsTableGlobalFiltersData

function useTableData(): OrderItemsTableGlobalFiltersData {
  const orderItems: Array<OrderItem> = useSelector(selectTableOrderItems)
  const tableOrderItems: TableOrderItem[] = orderItems
    .map(mapOrderItemToTableOrderItem)

  return {
    nameIds: tableOrderItems.map(orderItem => ({
      id: orderItem.id,
      title: orderItem.name,
      name: orderItem.name,
    })),
  }
}

export function OrderItemsTableGlobalFiltersSection() {
  const {nameIds} = useTableData()

  return (
    <div className='flex space-x-2 mr-1'>
      <Filter
        type='nameIds'
        data={nameIds.map(mapToSelectItem)}
      />
    </div>
  )
}

interface GlobalMultiSelectProps {
  type: FilterType,
  data: Array<SelectItem>,
}

function Filter({
  type,
  data,
}: GlobalMultiSelectProps) {
  const dispatch = useDispatch()
  const selectedIds: Array<string> = useSelector(selectOrderItemsTableGlobalFilter(type))
  const resultData: Array<SelectItem> = [
    // { value: EMPTY_VALUE_ID, label: '(Empty value)' },
    ...data,
  ]

  return (
    <GlobalMultiSelect
      selected={selectedIds}
      data={resultData}
      onChange={ids => {
        dispatch(setOrderItemsTableGlobalFilter({ field: type, value: ids }))
      }}
      label={<label className='text-xs'>{getLabel(type)}</label>}
      withinPortal={false}
    />
  )
}

function getLabel(type: FilterType) {
  switch (type) {
    case 'nameIds':
      return <IntlMessage id='orderTable.filters.name' />
    default:
      checkNever(type)
      return ''
  }
}