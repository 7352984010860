import {createSelector} from '@reduxjs/toolkit'
import {RootState} from '../types'

const auth = (state: RootState) => state.auth
export const selectToken = createSelector(auth, state => state.token)
export const selectLoading = createSelector(auth, state => state.loading)
export const selectMessage = createSelector(auth, state => state.message)
export const selectShowMessage = createSelector(auth, state => state.showMessage)
export const selectRedirect = createSelector(auth, state => state.redirect)
export const selectAuth = createSelector(auth, state => state.auth)
export const selectInitialEmail = createSelector(auth, state => state.email)
export const isAuthenticated = createSelector(auth, state => Boolean(state.token))
