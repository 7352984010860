
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { UserInfo } from '../store/user-info/reducer'
import { selectUserInfo } from '../store/user-info/selectors'

const ALLOWED_COMPANY_IDS: Array<string> = [
  '17318839',
  '13076663',
  '13899555',
  '13077179',
  '15685229',
  '23591807',
  '24084803',
  '25194683',
  '13076669',
  '15439325',
  '19269329',
  '16832465',
  '18013279',
  '24338329',
  '13076667',
  '13076875',
  '24352565',
  '23559511',
]
const ALLOWED_EMAILS: Array<string> = [
  'it@appscience.ru',
  'maltsev_alex@mail.ru',
]

export function useHasNewProposal(): boolean {
  const userInfo: UserInfo | null = useSelector(selectUserInfo)

  return useMemo(() => {
    if (!userInfo) {
      return false
    }

    const { email, company_id } = userInfo
    return (
      ALLOWED_COMPANY_IDS.includes(company_id)
      || ALLOWED_EMAILS.includes(email)
    )
  }, [userInfo])
}
