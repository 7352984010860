import {createSlice} from '@reduxjs/toolkit'
import {
  AUTH_TOKEN,
} from '../../redux/constants/Auth'

const initState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  token: localStorage.getItem(AUTH_TOKEN),
  email: '',
  auth:{
    email: '',
    password: '',
  },
}

const authSlice = createSlice({
  name: 'auth',
  initialState: {...initState},
  reducers: {
    authAction() {},
    authenticated(state, action) {
      state.loading = false
      state.redirect = '/'
      state.token = action.payload
    },
    setEmail(state, action) {
      state.email = action.payload
      localStorage.setItem('lk_email', action.payload)
    },
    showAuthMessage(state, action) {
      state.showMessage = true
      state.loading = false
      state.message = action.payload
    },
    hideAuthMessage(state) {
      state.message = ''
      state.showMessage = false
    },
    signOutSuccess(state) {
      state.token = null
      state.redirect = '/'
      state.loading = false
    },
    signUpSuccess(state, action) {
      state.loading = false
      state.token = action.payload
    },
    showLoading(state) {
      state.loading = true
    },

    signInWithGoogleAuthenticated(state, action) {
      state.loading = false
      state.token = action.payload
    },
    signInWithFacebookAuthenticated(state, action) {
      state.loading = false
      state.token = action.payload
    },
    signOut(state) {
      state.token = null
      state.loading = false
      localStorage.removeItem(AUTH_TOKEN)
    },
  },
})

export const authReducer = authSlice.reducer
export const {
  authAction,
  authenticated,
  showAuthMessage,
  hideAuthMessage,
  signOutSuccess,
  signUpSuccess,
  showLoading,
  signInWithGoogleAuthenticated,
  signInWithFacebookAuthenticated,
  setEmail,
  signOut,
} = authSlice.actions
