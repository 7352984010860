import { notification } from 'antd'
import UserInfoService, { GetUserInfoResponse } from '../../services/UserService'
import * as Sentry from '@sentry/react'
import { resetUserInfo, setUserInfo, setUserInfoLoading } from '../../store/user-info/reducer'
import {Dispatch} from 'redux'

// TODO redux-thunk
export function getUserInfo(dispatch: Dispatch<AnyType>): void {
  let isMounted = true
  dispatch(resetUserInfo())
  dispatch(setUserInfoLoading(true))

  UserInfoService
    .getUserInfo()
    .then((userInfoResponse: GetUserInfoResponse) => {
      isMounted = true
      dispatch(setUserInfo(userInfoResponse))
    })
    .catch((error: Error) => {
      if (!isMounted) {
        return
      }
      notification.error({
        message: 'Ошибка при получении данных пользователя',
        description: error.message,
        duration: 5,
      })
      Sentry.captureException(error)
      dispatch(resetUserInfo())
    })
    .finally(() => {
      if (!isMounted) {
        return
      }
      dispatch(setUserInfoLoading(false))
    })
}